<template>
  <div class="employment-application">
    <template v-if="viewType === 'list'">
      <!-- 列表 -->
      <list @changeType="changeType"></list>
    </template>
    <template v-else-if="viewType === 'detail'">
      <!-- 详情 -->
      <detail
        @changeType="changeType"
        :initId="currentId"
        :viewStatus="viewStatus"
      ></detail>
    </template>
    <template v-else>
      <!-- 新增 -->
      <add @changeType="changeType"></add>
    </template>
  </div>
</template>

<script>
import List from "./list";
import Detail from "./detail";
import Add from "./add";
export default {
  name: "employmentApplication",
  components: { Add, Detail, List },
  data() {
    return {
      currentId: "",
      viewType: "list", //list列表 & add新增 & detail详情
    };
  },
  methods: {
    changeType(data) {
      this.viewType = data.type;
      this.currentId = data.id;
      this.viewStatus = data.viewStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
.employment-application {
  // background-color: white;
}
</style>