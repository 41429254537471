<template>
  <div class="detail">
    <el-tabs type="card" class="" v-model="activeTab">
      <el-tab-pane label="当前流程" name="current">
        <basic-info v-model="form" :status="viewStatus"></basic-info>
        <block
          title="审批流程"
          class="approval-process"
          v-slot="scope"
          :status="viewStatus"
          v-if="isShowApproval"
        >
          <template>
            <div class="approval-process_wrapper">
              <div class="approval-process_top">
                <span>审批类型：</span>
                <el-select
                  v-model="region"
                  placeholder="请选择审批类型"
                  v-if="scope.status == 'edit' && form.status === 0"
                  @change="updateBpmModel"
                >
                  <el-option
                    label="武汉大悦城入职审批"
                    :value="501"
                  ></el-option>
                  <el-option
                    label="长沙大悦城入职审批"
                    :value="502"
                  ></el-option>
                  <el-option
                    label="其他平台区域项目入职审批"
                    :value="503"
                  ></el-option>
                </el-select>
                <span v-else>{{ form.activityKeyName }}</span>
              </div>
            </div>
            <template v-if="form.bpmActivityModel">
              <flow
                :flows="form.bpmActivityModel"
                :status="form.status"
                @updateFlows="updateFlows"
                v-if="viewStatus == 'edit'"
              ></flow>
              <detail-flow
                :flows="[form.activityModel.activitiNodeDetails]"
                :data-status="form.status"
                v-if="form.activityModel && viewStatus == 'saved'"
              ></detail-flow>
            </template>
          </template>
        </block>
        <user-info
          v-model="form"
          :status="viewStatus"
          v-if="form.status != 101"
        ></user-info>
      </el-tab-pane>
      <el-tab-pane label="历史记录" name="history" v-if="showHistory">
        <div class="history-record">
          <template v-if="historyList && historyList.length">
            <div
              class="record-item"
              v-for="(record, index) of historyList"
              :key="index"
              style="
                border: 1px solid lightgray;
                margin-bottom: 16px;
                padding: 16px;
              "
            >
              <basic-info :value="record" status="saved"></basic-info>
              <block
                title="审批流程"
                class="approval-process"
                v-slot="scope"
                :status="viewStatus"
                v-if="isShowApproval"
              >
                <template>
                  <div class="approval-process_wrapper">
                    <div class="approval-process_top">
                      <span>审批类型：</span>
                      <span>{{ record.activityKeyName }}</span>
                    </div>
                  </div>
                  <template>
                    <detail-flow
                      :flows="[record.activityModel.activitiNodeDetails]"
                      :data-status="record.status"
                      v-if="record.activityModel"
                    ></detail-flow>
                  </template>
                </template>
              </block>
              <user-info :value="record" status="saved"></user-info>
            </div>
          </template>
          <template v-else>
            <div class="no-record-data">暂无数据</div>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div style="height: 76px"></div>
    <div class="footer">
      <el-button @click="back" size="medium">返回</el-button>
      <el-button
        @click="submit"
        type="primary"
        v-if="[0, 3, 5].includes(form.status) && viewStatus == 'edit'"
        v-throttle
        size="medium"
        >提交</el-button
      >
      <!-- <el-button
        size="medium"
        @click="save"
        type="primary"
        v-if="[0].includes(form.status) && viewStatus == 'edit'"
        >保存</el-button
      > -->
    </div>
  </div>
</template>

<script>
import Block from "@/components/block.vue";
import BasicInfo from "@/businessComponents/basicInfo.vue";
import SelectApproval from "@/components/selectApproval.vue";
import UserInfo from "@/businessComponents/userInfo.vue";
import Flow from "@/components/flow.vue";
import DetailFlow from "@/components/detail-flow.vue";

export default {
  name: "detail",
  components: { Block, SelectApproval, UserInfo, BasicInfo, Flow, DetailFlow },
  data() {
    return {
      hasEdit: false,
      hasInit: false,
      historyList: [],
      activeTab: "current",
      form: {
        activityStatus: "",
        age: "",
        bankAccount: "",
        bankName: "",
        birthAddress: {
          cityCode: "",
          cityName: "",
          code: "",
          name: "",
          provinceCode: "",
          provinceName: "",
        },
        birthday: "",
        bpmActivityModel: {
          activitiKey: "",
          activitiName: "",
          creator: "",
          enterpriseCode: "",
          gmtCreate: "",
          gmtModified: "",
          id: "",
          modifier: "",
          nodeList: [
            {
              activitId: "",
              departments: "",
              id: "",
              isAll: "",
              isOwnSetting: "",
              nodeAuditDefaultUser: "",
              nodeAuditNullType: "",
              nodeAuditType: "",
              nodeName: "",
              nodeType: "",
              sort: "",
              timeOutSwitch: "",
              timeOutTriggerTime: "",
              timeOutTriggerType: "",
              timeOutWarningTime: "",
              timeOutWarningType: "",
              userList: [
                {
                  deptIds: [],
                  deptName: "",
                  disable: true,
                  email: "",
                  enterpriseId: "",
                  id: "",
                  name: "",
                  pageNum: "",
                  pageSize: "",
                  phoneNo: "",
                  positionName: "",
                  postIds: [],
                  postName: "",
                  remark: "",
                  uuid: "",
                },
              ],
              users: "",
            },
          ],
          version: "",
        },
        computerLevel: "",
        deptId: 0,
        education: "",
        emailAddress: "",
        familyMembers: [],
        foreignLanguage: [],
        guarantorAddress: "",
        guarantorIdCard: "",
        guarantorName: "",
        guarantorPhone: "",
        guarantorRelation: "",
        homeAddress: "",
        id: "",
        idCard: "",
        inducApprStatus: "",
        joinPartyTime: "",
        joinWorkTime: "",
        learnExperience: [
          {
            academicDegree: "",
            beginTime: "",
            education: "",
            endTime: "",
            learnMethod: "",
            schoolName: "",
            specialty: "",
            witness: "",
          },
        ],
        maritalStatus: "",
        marketId: "",
        mobile: "",
        nation: "",
        personnelFilePlace: "",
        politicalStatus: "",
        positionIds: [],
        preEntryTime: "",
        ptPositionIds: "",
        rankLevelIds: [],
        residenceAddress: "",
        sex: "M",
        staffName: "",
        technicalTitle: "",
        uploadMaterial: [
          {
            id: "",
            materialList: [],
            materialName: "",
            type: "",
          },
        ],
        workAge: "",
        workExperience: [
          {
            beginTime: "",
            companyName: "",
            deptName: "",
            endTime: "",
            position: "",
            workNature: "",
          },
        ],
        workPlace: {
          cityCode: "",
          cityName: "",
          code: "",
          detail: "",
          name: "",
          provinceCode: "",
          provinceName: "",
        },
      },
      region: 501,
      id: this.initId,
    };
  },
  props: {
    initId: {
      type: String,
    },
    viewStatus: {
      type: String,
      default() {
        return "saved";
      },
    },
    showHistory: {
      type:Boolean,
      default(){
        return true
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    changeApproval(item, val) {
      this.$set(item, "visible", val);
      this.$forceUpdate();
    },
    confirmApproval(value, ele) {
      value.id = value.userId;
      ele.userList[0] = value;
      ele.users = value.userId + "";
    },
    validate() {
      if (!this.region) {
        this.$message.warning("请选择审批类型");
        return false;
      }
      if (
        this.form.bpmActivityModel
          .filter((e) => e.nodeType == 1)
          .every((e) => e.userList.length == 0)
      ) {
        this.$message.warning("请至少选择一个审批人");
        return false;
      }
      return true;
    },
    save() {
      this.form.uploadMaterial.forEach((e) => {
        if (e.type == 2) {
          e.id = 0;
        }
      });
      this.form.activityNodeDetails = this.form.bpmActivityModel;
      this.form.activityKeyValue = this.region;
      this.$api.personnel.inductionApprovalHrEdit(this.form).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    updateFlows(data) {
      this.form.bpmActivityModel = data;
      this.form.bpmActivityModel.forEach((node) => {
        node.users = node.userList.map((e) => e.id).join(",");
      });
    },
    submit() {
      if (!this.validate()) {
        return;
      }
      let func = this.$api.personnel.inductionApprovalInsert;
      if (this.id) {
        func = this.$api.personnel.inductionApprovalEditAndSubmit;
        // if (this.form.status == 5) {
        //   // 驳回待修改
        //   func = this.$api.personnel.inductionApprovalUpdateAndActivate;
        // }
        if (this.form.status == 3) {
          // 驳回待修改
          if (!this.hasEdit) {
            return this.$message.warning("请修改后再提交");
          }
          func = this.$api.personnel.inductionApprovalUpdateAndActivate;
        }
        this.form.activityNodeDetails = this.form.bpmActivityModel;
        this.form.activityKeyValue = this.region;
      }
      this.form.uploadMaterial.forEach((e) => {
        if (e.type == 2) {
          e.id = 0;
        }
      });
      func(this.form).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          setTimeout(() => {
            this.$emit("changeType", {
              type: "list",
            });
          }, 500);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    back() {
      this.$emit("changeType", {
        type: "list",
      });
    },
    getHistory() {
      this.$api.personnel
        .inductionApprovalGetHistory({ id: this.id })
        .then(({ data }) => {
          if (data) {
            this.historyList = data.list;
          }
        });
    },
    async init() {
      if (this.id) {
        if (this.viewStatus == "edit") {
          let { data: data0 } = await this.$api.personnel.inductionApprovalGet(
            this.id
          );
          if (data0.status == 3) {
            let { data: data1 } = await this.$api.checkapi.getRefuseDraft(
              this.id
            );
            if (data1.id) {
              this.id = data1.id;
            } else {
              let { data: data2 } =
                await this.$api.personnel.inductionApprovalInsertRefuseDraft({
                  id: this.id,
                });
              this.id = data2.id;
            }
          }
        }
        this.$api.personnel.inductionApprovalGet(this.id).then((res) => {
          if (res.data) {
            if (res.data.hasHistory) {
              this.getHistory();
            }
            res.data.computerLevel += "";
            if (
              res.data.activityModel &&
              res.data.activityModel.activitiNodeDetails
            ) {
              // 如果已经保存了审批流程，则展示审批流程，如果没有才请求模板数据
              res.data.bpmActivityModel =
                res.data.activityModel.activitiNodeDetails;
              this.region = res.data.activityKeyValue;
            } else {
              this.updateBpmModel(this.region);
            }
            this.form = res.data;
            this.$nextTick(() => {
              this.hasInit = true;
            });
          }
        });
      }
    },
    updateBpmModel(val) {
      this.$api.personnel.bpmGetModelValue(val).then((res) => {
        res.data &&
          (this.form.bpmActivityModel = res.data.nodeList.map((ele) => {
            if (!ele.userList) {
              ele.userList = [];
            }
            this.$set(ele, "visible", false);
            this.$forceUpdate();
            return ele;
          }));
      });
    },
  },
  computed: {
    isShowApproval() {
      if (this.form.status == 101) {
        // 待入职员工填写时，不能展示审批流程与下面的个人信息模块
        return false;
      } else if (this.form.status == 0) {
        // 当入职员工已经填写完毕，则需要判断
        // 如果是查看状态下的页面
        // 如果已经保存过审核流程，则展示，否则不展示
        // 如果是编辑状态下的页面
        // 则需要展示
        if (this.viewStatus == "saved") {
          if (
            this.form.activityModel &&
            this.form.activityModel.activitiNodeDetails
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (this.hasInit) {
          this.hasEdit = true;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  padding: 40px;
  background-color: white;
  .approval-process {
    .approval-process_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .approval-process_top {
        height: 20px;
      }
    }
    .steps {
      width: 100%;
      margin: 40px 0;
    }
    .step-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    background-color: white;
    left: 262px;
    display: flex;
    width: calc(100% - 282px);
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    z-index: 10;
  }
}
</style>

<style lang="scss">
.approval-process {
  .el-step__description {
    padding-right: 0;
  }
}
</style>