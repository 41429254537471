<template>
  <div class="user-info">
    <block title="学习经历" v-slot="scope" :status="$attrs['status']">
      <template v-if="scope.status == 'saved' && _value.learnExperience">
        <el-form v-for="(e, i) in _value.learnExperience" :key="i">
          <p>学习经历{{ i + 1 }}</p>
          <el-form-item label="起始日期">
            {{ e.beginTime }}
          </el-form-item>
          <el-form-item label="结束日期">
            {{ e.endTime }}
          </el-form-item>
          <el-form-item label="学位">
            {{ e.academicDegree | map("academicDegree") }}
          </el-form-item>
          <el-form-item label="学历">
            {{ e.education | map("education") }}
          </el-form-item>
          <el-form-item label="学习方式">
            {{ e.learnMethod | map("learnMethod") }}
          </el-form-item>
          <el-form-item label="所在院校">
            {{ e.schoolName }}
          </el-form-item>
          <el-form-item label="专业">
            {{ e.specialty }}
          </el-form-item>
          <el-form-item label="证明人">
            {{ e.witness }}
          </el-form-item>
        </el-form>
      </template>
      <template v-if="scope.status == 'edit' && _value.learnExperience">
        <el-form v-for="(e, i) in _value.learnExperience" :key="i">
          <p>学习经历{{ i + 1 }}</p>
          <el-form-item label="起始日期">
            <el-date-picker
              v-model="e.beginTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期">
            <el-date-picker
              v-model="e.endTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="学位">
            <el-select v-model="e.academicDegree" placeholder="请选择学位">
              <el-option
                :label="ele.label"
                :value="ele.value"
                v-for="(ele, index) in $map.getter('academicDegree')"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学历">
            <el-select v-model="e.education" placeholder="请选择学历">
              <el-option
                :label="ele.label"
                :value="ele.value"
                v-for="(ele, index) in $map.getter('education')"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学习方式">
            <el-select v-model="e.learnMethod" placeholder="请选择学习方式">
              <el-option
                :label="ele.label"
                :value="ele.value"
                v-for="(ele, index) in $map.getter('learnMethod')"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在院校">
            <el-input v-model="e.schoolName"></el-input>
          </el-form-item>
          <el-form-item label="专业">
            <el-input v-model="e.specialty"></el-input>
          </el-form-item>
          <el-form-item label="证明人">
            <el-input v-model="e.witness"></el-input>
          </el-form-item>
          <el-button
            @click="remove('learnExperience', i)"
            class="remove"
            type="danger"
            >删除</el-button
          >
        </el-form>
        <el-button @click="add('learnExperience')" type="primary"
          >新增</el-button
        >
      </template>
    </block>
    <block title="工作经历" v-slot="scope" :status="$attrs['status']">
      <template v-if="scope.status == 'saved' && _value.workExperience">
        <el-form v-for="(e, i) in _value.workExperience" :key="i">
          <p>工作经历{{ i + 1 }}</p>
          <el-form-item label="起始日期">
            {{ e.beginTime }}
          </el-form-item>
          <el-form-item label="结束日期">
            {{ e.endTime }}
          </el-form-item>
          <el-form-item label="工作单位">
            {{ e.companyName }}
          </el-form-item>
          <el-form-item label="工作部门">
            {{ e.deptName }}
          </el-form-item>
          <el-form-item label="职务">
            {{ e.position }}
          </el-form-item>
          <el-form-item label="工作性质">
            {{ e.workNature | map("workNature") }}
          </el-form-item>
          <el-form-item label="证明人">
            {{ e.witness }}
          </el-form-item>
        </el-form>
      </template>
      <template v-if="!_value.workExperience">无工作经历</template>
      <template v-if="scope.status == 'edit' && _value.workExperience">
        <el-form v-for="(e, i) in _value.workExperience" :key="i">
          <p>工作经历{{ i + 1 }}</p>
          <el-form-item label="起始日期">
            <el-date-picker
              v-model="e.beginTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期">
            <el-date-picker
              v-model="e.endTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="工作单位">
            <el-input v-model="e.companyName"></el-input>
          </el-form-item>
          <el-form-item label="工作部门">
            <el-input v-model="e.deptName"></el-input>
          </el-form-item>
          <el-form-item label="职务">
            <el-input v-model="e.position"></el-input>
          </el-form-item>
          <el-form-item label="工作性质">
            <el-select v-model="e.workNature" placeholder="请选择工作性质">
              <el-option
                :label="ele.label"
                :value="ele.value"
                v-for="(ele, index) in $map.getter('workNature')"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证明人">
            <el-input v-model="e.witness"></el-input>
          </el-form-item>
          <el-button
            @click="remove('workExperience', i)"
            class="remove"
            type="danger"
            >删除</el-button
          >
        </el-form>
        <el-button @click="add('workExperience')" type="primary"
          >新增</el-button
        >
      </template>
    </block>
    <block title="其他技能情况" v-slot="scope" :status="$attrs['status']">
      <template v-if="scope.status == 'saved' && _value.foreignLanguage">
        <el-form>
          <el-form-item label="计算机应用水平">
            {{ _value.computerLevel | map("computerLevel") }}
          </el-form-item>
        </el-form>
        <el-form v-for="(e, i) in _value.foreignLanguage" :key="i">
          <p>外语技能{{ i + 1 }}</p>
          <el-form-item label="外语水平">
            {{ e.level }}
          </el-form-item>
          <el-form-item label="语种">
            {{ e.language }}
          </el-form-item>
          <el-form-item label="考试种类及种类">
            {{ e.examAndLevel }}
          </el-form-item>
          <el-form-item label="阅读">
            {{ e.read }}
          </el-form-item>
          <el-form-item label="听说">
            {{ e.listen }}
          </el-form-item>
          <el-form-item label="写作">
            {{ e.write }}
          </el-form-item>
        </el-form>
      </template>
      <template v-if="scope.status == 'edit' && _value.foreignLanguage">
        <el-form>
          <el-form-item label="计算机应用水平">
            <el-select
              v-model="_value.computerLevel"
              placeholder="请选择计算机应用水平"
            >
              <el-option
                :label="ele.label"
                :value="ele.value"
                v-for="(ele, index) in $map.getter('computerLevel')"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form v-for="(e, i) in _value.foreignLanguage" :key="i">
          <p>外语技能{{ i + 1 }}</p>
          <el-form-item label="外语水平">
            <el-input v-model="e.level"></el-input>
          </el-form-item>
          <el-form-item label="语种">
            <el-input v-model="e.language"></el-input>
          </el-form-item>
          <el-form-item label="考试种类及种类">
            <el-input v-model="e.examAndLevel"></el-input>
          </el-form-item>
          <el-form-item label="阅读">
            <el-input v-model="e.read"></el-input>
          </el-form-item>
          <el-form-item label="听说">
            <el-input v-model="e.listen"></el-input>
          </el-form-item>
          <el-form-item label="写作">
            <el-input v-model="e.write"></el-input>
          </el-form-item>
          <el-button
            @click="remove('foreignLanguage', i)"
            class="remove"
            type="danger"
            >删除</el-button
          >
        </el-form>
        <el-button @click="add('foreignLanguage')" type="primary"
          >新增</el-button
        >
      </template>
    </block>
    <block title="家庭成员情况" v-slot="scope" :status="$attrs['status']">
      <template v-if="scope.status == 'saved' && _value.familyMembers">
        <el-form v-for="(e, i) in _value.familyMembers" :key="i">
          <p>家庭成员情况{{ i + 1 }}</p>
          <el-form-item label="性别">
            {{ e.sex | map("sex") }}
          </el-form-item>
          <el-form-item label="年龄">
            {{ e.age }}
          </el-form-item>
          <el-form-item label="关系">
            {{ e.relation }}
          </el-form-item>
          <el-form-item label="现任职单位或居住地址">
            {{ e.address }}
          </el-form-item>
          <el-form-item label="联系电话">
            {{ e.phone }}
          </el-form-item>
        </el-form>
      </template>
      <template v-if="scope.status == 'edit' && _value.familyMembers">
        <el-form v-for="(e, i) in _value.familyMembers" :key="i">
          <p>家庭成员情况{{ i + 1 }}</p>
          <el-form-item label="性别">
            <el-radio
              v-model="e.sex"
              :label="ele.value"
              v-for="(ele, index) in $map.getter('sex')"
              :key="index"
              >{{ ele.label }}</el-radio
            >
          </el-form-item>
          <el-form-item label="年龄">
            <el-input v-model="e.age"></el-input>
          </el-form-item>
          <el-form-item label="关系">
            <el-input v-model="e.relation"></el-input>
          </el-form-item>
          <el-form-item label="现任职单位或居住地址">
            <el-input v-model="e.address"></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="e.phone"></el-input>
          </el-form-item>
          <el-button
            @click="remove('familyMembers', i)"
            class="remove"
            type="danger"
            >删除</el-button
          >
        </el-form>
        <el-button @click="add('familyMembers')" type="primary">新增</el-button>
      </template>
    </block>
    <block
      title="资料上传"
      v-slot="scope"
      class="upload-files"
      :status="$attrs['status']"
    >
      <template v-if="scope.status == 'edit' && _value.uploadMaterial">
        <el-form v-for="(e, i) in _value.uploadMaterial" :key="i">
          <el-form-item :label="e.materialName">
            <template v-slot:label>
              <div v-if="e.type == 2">
                <!-- 类型，默认：1；自定义：2； -->
                <el-input v-model="e.materialName"></el-input>
              </div>
            </template>
            <el-upload
              :ref="`upload${i}`"
              v-viewer="{ toolbar: false }"
              action="/business/upload"
              multiple
              :limit="3"
              :http-request="(v) => uploadFile(v, e, i, _value.uploadMaterial)"
              :on-remove="(file, fileList) => deleteFile(file, fileList, e)"
              :file-list="e.fileList"
              list-type="picture"
              accept=".jpg,.jpeg,.png,.pdf,.zip,.rar"
              :title="e.fileList.map((e) => e.fullName).join('\n')"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-button @click="add('uploadMaterial')" type="primary"
          >新增</el-button
        >
      </template>
      <template v-if="scope.status == 'saved' && _value.uploadMaterial">
        <el-form v-for="(e, i) in _value.uploadMaterial" :key="i">
          <el-form-item :label="e.materialName">
            <div v-viewer="{ toolbar: false }">
              <img
                :src="ele"
                v-for="(ele, index) in e.materialList"
                :key="index"
                style="width: 100px; height: 100px"
              />
            </div>
          </el-form-item>
        </el-form>
      </template>
    </block>
  </div>
</template>

<script>
import { processUpload } from "../services/check";
import zipLogo from "../assets/zip.png";
import rarLogo from "../assets/rar.png";
import pdfLogo from "../assets/pdf.png";
import Block from "@/components/block";
export default {
  name: "UserInfo",
  components: {
    Block,
  },
  data() {
    return {
      fileList: [],
    };
  },
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    _value: {
      get() {
        this.value.uploadMaterial &&
          this.value.uploadMaterial.map((e) => {
            e.fileList = (e.materialList||[]).map((ele) => {
              let path = ele.split("/");
              let fullName = path[path.length - 1];
              return {
                name: this.sliceName(fullName),
                fullName,
                url: ele,
              };
            });
            return e;
          });
        this.value.learnExperience &&
          this.value.learnExperience.forEach((e) => {
            e.academicDegree += "";
            e.education += "";
            e.learnMethod += "";
          });
        this.value.workExperience &&
          this.value.workExperience.forEach((e) => {
            e.workNature += "";
          });
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  updated() {
    this._value.uploadMaterial.forEach((e, i) => {
      this.setImgLogo(i);
    });
  },
  methods: {
    sliceName(fullName, sliceIndex = 10) {
      let name = fullName;
      if (fullName.length > sliceIndex) {
        name = `...${fullName.slice(-sliceIndex)}`;
      }
      return name;
    },
    setImgLogo(index) {
      let uploads = this.$refs[`upload${index}`];
      if (!uploads) {
        return;
      }
      let picList = uploads[0].$children[1].$el.childNodes;
      Array.prototype.map
        .call(picList, (dom) => dom.childNodes[0])
        .forEach((imgDom, i) => {
          let src = imgDom.src;
          let postfix = src.slice(src.lastIndexOf(".") + 1);
          if (["pdf", "zip", "rar"].includes(postfix)) {
            imgDom.src = {
              pdf: pdfLogo,
              zip: zipLogo,
              rar: rarLogo,
            }[postfix];
          }
        });
      // 可能可以考虑改用document.querySelector来做，逻辑上更清楚
    },
    deleteFile(file, filelist, target) {
      //  删除文件
      filelist = filelist.filter((ite) => {
        return ite && ite.url !== file.url;
      });
      target.fileList = filelist;
      target.materialList = target.materialList.filter((e) => file.url != e);
    },
    async uploadFile(data, ele, index, list) {
      const file = data.file;
      if (file) {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("biz", 5);
        processUpload(formData)
          .then(({ code, data, msg }) => {
            if (code === 200) {
              let obj = JSON.parse(JSON.stringify(ele));
              obj.fileList.push({
                name: this.sliceName(data.fileName),
                url: data.fileName,
              });
              obj.materialList.push(data.fileUrl);
              this.$set(list, index, obj);
            }
          })
          .catch((err) => {
            // console.log(err, data, ele);
          })
          .finally(() => {
            this.setImgLogo(index);
          });
      }
    },
    add(name) {
      const MAP = {
        workExperience: {
          beginTime: "",
          companyName: "",
          deptName: "",
          endTime: "",
          position: "",
          workNature: "",
        },
        learnExperience: {
          academicDegree: 0,
          beginTime: "",
          education: 0,
          endTime: "",
          learnMethod: 0,
          schoolName: "",
          specialty: "",
          witness: "",
        },
        uploadMaterial: {
          materialList: [],
          name: "",
          type: 2,
        },
        familyMembers: {
          address: "",
          age: "",
          name: "",
          phone: "",
          relation: "",
          sex: "M",
        },
        foreignLanguage: {
          level: "",
          language: "",
          examAndLevel: "",
          read: "",
          listen: "",
          write: "",
        },
      };
      this._value[name].push(MAP[name]);
    },
    remove(name, index) {
      this._value[name].splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info {
  .block {
    .el-form {
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        width: 50%;
        flex-shrink: 0;
        display: flex;
      }
      & > p {
        width: 100%;
        margin: 0 0 10px 0;
        font-weight: 600;
      }
      .remove {
        position: absolute;
        right: 40px;
      }
    }
  }
}
</style>

<style lang="scss">
.upload-files {
  .el-form-item {
    .el-form-item__label {
      width: 250px;
      // text-align: left;
    }
  }
}
.user-info {
  .el-form-item__label {
    width: 150px;
  }
}
</style>