<template>
  <div class="searchBox search-box">
    <div class="searchForm">
      <div class="item searchItem">
        <span>姓名：</span>
        <el-input v-model="staffName" size="medium"></el-input>
      </div>
      <div class="item searchItem">
        <span style="margin-left: 16px">手机号：</span>
        <el-input v-model="mobile" size="medium"></el-input>
      </div>
      <div class="item searchItem">
        <span>状态：</span>
        <el-select clearable v-model="status" placeholder="请选择" size="medium">
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item searchItem">
        <el-button
          @click="search"
          type="primary"
          style="margin-left: 16px"
          size="medium"
          >查询
        </el-button>
        <el-button @click="_export" type="primary" style="margin-left: 16px" size="medium">导出</el-button>
        <el-button @click="reset()" style="margin-left: 16px" size="medium"
          >重置
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "searchBox",
  data() {
    return {
      staffName: "",
      mobile: "",
      statusList: [
        { label: "待入职员工填写", value: 101 },
        { label: "未提审", value: 0 },
        { label: "已撤销", value: 1 },
        { label: "处理中", value: 2 },
        { label: "已驳回", value: 3 },
        { label: "已通过", value: 4 },
        { label: "已终止", value: 7 },
        // { label: "驳回待修改", value: 5 },
      ],
      status: "",
    };
  },
  methods: {
    search() {
      this.$emit("change", this.data);
    },
    reset() {
      this.staffName = "";
      this.mobile = "";
      this.status = "";
      this.$emit("change", this.data);
    },
    _export(){
      this.$emit("export", Object.assign(this.data,{statusList:this.data.statusList.join(',')}));
    }
  },
  computed: {
    data() {
      return {
        staffName: this.staffName,
        mobile: this.mobile,
        statusList: this.status === '' ? [] : [this.status],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  .item {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
  }
}
</style>
